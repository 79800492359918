<template>
    <svg class="svg-social" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g class="normal">
            <circle cx="16" cy="16" r="16" fill="black" fill-opacity="1" />
            <path
                d="M23.9963 13.8767C24.0323 12.8589 23.8047 11.8487 23.3349 10.9399C23.0161 10.567 22.5737 10.3153 22.0848 10.2288C20.0625 10.0492 18.0317 9.97563 16.0014 10.0083C13.9784 9.97415 11.955 10.0454 9.93973 10.2216C9.54131 10.2926 9.17259 10.4754 8.87858 10.7479C8.22445 11.3381 8.15177 12.3479 8.07909 13.2012C7.97364 14.7355 7.97364 16.2749 8.07909 17.8092C8.10012 18.2895 8.17321 18.7662 8.29713 19.2314C8.38477 19.5906 8.56207 19.9228 8.81317 20.1985C9.10918 20.4854 9.48648 20.6787 9.89612 20.7532C11.4631 20.9424 13.0419 21.0208 14.6204 20.9878C17.1643 21.0234 19.3956 20.9878 22.0339 20.7887C22.4536 20.7188 22.8415 20.5253 23.1459 20.2341C23.3494 20.0349 23.5014 19.7911 23.5893 19.523C23.8492 18.7426 23.9769 17.9259 23.9672 17.1052C23.9963 16.707 23.9963 14.3034 23.9963 13.8767ZM14.3588 17.5318V13.1301L18.6615 15.3416C17.455 15.9959 15.8633 16.7354 14.3588 17.5318Z"
                fill="white"
            />
            <g class="colored">
                <circle cx="16" cy="16" r="16" fill="#EE1D1A" />
                <path
                    d="M23.9963 13.8767C24.0323 12.8589 23.8047 11.8487 23.3349 10.9399C23.0161 10.567 22.5737 10.3153 22.0848 10.2288C20.0625 10.0492 18.0317 9.97563 16.0014 10.0083C13.9784 9.97415 11.955 10.0454 9.93973 10.2216C9.54131 10.2926 9.17259 10.4754 8.87858 10.7479C8.22445 11.3381 8.15177 12.3479 8.07909 13.2012C7.97364 14.7355 7.97364 16.2749 8.07909 17.8092C8.10012 18.2895 8.17321 18.7662 8.29713 19.2314C8.38477 19.5906 8.56207 19.9228 8.81317 20.1985C9.10918 20.4854 9.48648 20.6787 9.89612 20.7532C11.4631 20.9424 13.0419 21.0208 14.6204 20.9878C17.1643 21.0234 19.3956 20.9878 22.0339 20.7887C22.4536 20.7188 22.8415 20.5253 23.1459 20.2341C23.3494 20.0349 23.5014 19.7911 23.5893 19.523C23.8492 18.7426 23.9769 17.9259 23.9672 17.1052C23.9963 16.707 23.9963 14.3034 23.9963 13.8767ZM14.3588 17.5318V13.1301L18.6615 15.3416C17.455 15.9959 15.8633 16.7354 14.3588 17.5318Z"
                    fill="white"
                />
            </g>
        </g>
    </svg>
</template>
