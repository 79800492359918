<template>
    <svg class="svg-social" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g class="normal">
            <circle cx="22" cy="22" r="22" fill="black" fill-opacity="1" />
            <path
                d="M28.602 23.0791V20.8095H30.818V18.9682H29.4606V16.6879H30.7238C30.7816 16.6151 30.8138 16.5937 30.818 16.568C30.8766 16.1569 31.1199 15.9506 31.5482 15.9492C32.0064 15.9492 32.4645 15.9535 32.9206 15.9599C32.9634 15.9599 33.0041 15.992 33.0598 16.0135C33.0683 16.0691 33.0919 16.1291 33.0833 16.1847C33.017 16.6087 33.2289 16.7115 33.6079 16.6815C34.7555 16.5958 35.7255 16.9641 36.2436 18.0239C36.492 18.5314 36.5027 19.163 36.5798 19.7454C36.6247 20.0708 36.5883 20.4091 36.5883 20.7517C36.8924 20.7967 37.1493 20.816 37.3977 20.8738C38.3976 21.105 39.0806 21.9593 39.087 22.987C39.0934 24.0255 39.0977 25.0639 39.0913 26.1023C39.0848 27.2243 38.2734 28.0208 37.1493 28.0336C36.5369 28.04 35.916 28.0722 35.4064 27.6461C34.9482 27.2628 34.6399 26.8003 34.6913 26.1195C35.2887 26.1195 35.8518 26.1023 36.4106 26.1259C36.7446 26.1409 36.8324 26.006 36.8238 25.6998C36.8067 25.0596 36.8238 24.4173 36.811 23.7771C36.8024 23.3146 36.5947 23.0941 36.1408 23.0877C35.2416 23.0727 34.3402 23.0919 33.4388 23.0791C33.1005 23.0748 33.0833 23.2611 33.0833 23.518C33.0898 24.8305 33.0876 26.143 33.0876 27.4577C33.0876 27.6247 33.0876 27.7895 33.0876 28.0293C32.3768 28.0293 31.698 28.04 31.0214 28.0122C30.9572 28.0101 30.8566 27.7553 30.8566 27.6161C30.8437 26.2886 30.8287 24.959 30.8566 23.6315C30.8651 23.1862 30.7345 23.0448 30.2935 23.0727C29.7475 23.1091 29.1972 23.0812 28.5999 23.0812L28.602 23.0791ZM34.2909 20.7603C34.2909 20.27 34.308 19.8032 34.2802 19.3386C34.2738 19.2187 34.1496 19.0517 34.0361 19.0024C33.4666 18.7476 33.0962 18.9982 33.094 19.6126C33.094 19.6726 33.094 19.7347 33.094 19.7946C33.0926 20.5355 33.4923 20.8573 34.2931 20.7603H34.2909Z"
                fill="white"
            />
            <path
                d="M27.758 16.6941V19.0236H26.347C26.332 19.1971 26.3085 19.3277 26.3085 19.4583C26.3063 21.4281 26.3149 23.3979 26.2978 25.3656C26.2935 25.751 26.4155 25.8709 26.7923 25.8538C27.3533 25.8281 27.9143 25.8473 28.5074 25.8473V28.0077H20.7651C21.1099 27.3054 21.4182 26.6438 21.765 26.0058C21.8207 25.9051 22.0477 25.858 22.1954 25.8538C22.7885 25.8366 23.3816 25.8473 24.0089 25.8473V19.0429H22.5572C22.5572 18.3042 22.5487 17.6212 22.5679 16.9403C22.5701 16.8547 22.7242 16.7048 22.8078 16.7027C24.4393 16.6877 26.0708 16.692 27.7558 16.692L27.758 16.6941Z"
                fill="white"
            />
            <path
                d="M7.86957 26.1754C8.23142 26.1754 8.56543 26.1561 8.89516 26.1797C9.24202 26.2054 9.34265 26.0641 9.34051 25.7322C9.33195 23.7174 9.34051 21.7026 9.34051 19.6879C9.34051 18.6045 9.33837 17.5211 9.33837 16.4355C9.33837 16.2878 9.35122 16.1379 9.35978 15.9688H11.6058C11.6144 16.1486 11.6294 16.3135 11.6294 16.4783C11.6294 19.6686 11.6379 22.8588 11.6208 26.0491C11.6208 26.4195 11.5373 26.8113 11.4024 27.1582C11.1776 27.7363 10.7237 28.0424 10.0835 28.0296C9.76445 28.0232 9.43472 28.0531 9.1264 27.9911C8.34704 27.8369 7.86743 27.1496 7.86743 26.1754H7.86957Z"
                fill="white"
            />
            <path
                d="M22.1271 19.0592C21.504 20.2882 20.9238 21.4272 20.3478 22.5706C20.1102 23.0416 20.1787 23.1487 20.7182 23.1551C21.0073 23.1594 21.2963 23.1551 21.6839 23.1551C21.3413 23.7739 21.0458 24.3327 20.7204 24.8723C20.6711 24.9536 20.4784 24.975 20.35 24.9772C19.6327 24.9858 18.9154 24.9858 18.1982 24.9772C17.6436 24.9686 17.4124 24.7374 17.5515 24.1978C17.6864 23.6733 17.9434 23.1808 18.1682 22.6819C18.3844 22.2023 18.6264 21.7334 18.9047 21.1617C18.3545 21.1617 17.9198 21.166 17.4852 21.1617C16.8343 21.1553 16.5559 20.8384 16.7872 20.2282C17.0826 19.4489 17.4766 18.7038 17.8406 17.9501C18.1189 17.3741 18.408 16.8024 18.7099 16.2372C18.772 16.1194 18.9111 15.9696 19.0203 15.9653C19.6562 15.9396 20.2922 15.9524 21.0223 15.9524C20.6005 16.7661 20.2151 17.5026 19.834 18.2413C19.7076 18.4854 19.5685 18.723 19.4721 18.9778C19.4421 19.057 19.5085 19.2091 19.5792 19.2776C19.6177 19.314 19.7612 19.2562 19.8532 19.224C19.986 19.1769 20.1123 19.072 20.2429 19.0699C20.8488 19.0528 21.4548 19.0635 22.1314 19.0635L22.1271 19.0592Z"
                fill="white"
            />
            <path
                d="M5.50345 19.082H7.81585C7.77731 19.9535 7.79658 20.8078 7.68952 21.6471C7.52466 22.9424 7.30198 24.2335 7.04077 25.5118C6.94013 26.0064 6.72174 26.501 6.17148 26.8071C5.76895 26.0663 5.36642 25.3383 4.97888 24.6018C4.93606 24.5204 4.94676 24.3941 4.97246 24.2999C5.40282 22.6812 5.54413 21.0326 5.47562 19.3625C5.47134 19.2747 5.49275 19.1869 5.50559 19.082H5.50345Z"
                fill="white"
            />
            <path
                d="M14.8174 26.8114C14.2949 26.5095 14.1194 26.0342 13.9631 25.5632C13.4256 23.9381 13.3079 22.2487 13.2329 20.5551C13.2115 20.0734 13.2287 19.5895 13.2287 19.082H15.479C15.4875 19.1998 15.5068 19.3154 15.5046 19.4289C15.4533 21.069 15.6053 22.6877 16.0121 24.2828C16.0378 24.3813 16.0635 24.5097 16.0207 24.589C15.6331 25.3255 15.2306 26.0556 14.8174 26.8136V26.8114Z"
                fill="white"
            />
            <path
                d="M16.0331 27.8732C16.4399 27.0874 16.7953 26.398 17.125 25.7578H20.6814C20.2789 26.5179 19.9342 27.1924 19.5638 27.8497C19.506 27.9524 19.324 28.0359 19.1976 28.0381C18.3455 28.0531 17.4912 28.0509 16.639 28.0338C16.4549 28.0295 16.2729 27.9396 16.0331 27.8732Z"
                fill="white"
            />
            <path
                d="M36.8765 18.8603C36.8765 18.4364 36.8465 18.0253 36.8829 17.6228C36.9321 17.0897 37.4139 16.6914 37.9492 16.6914C38.5101 16.6914 38.9833 17.0961 39.0604 17.6399C39.1375 18.1902 38.7949 18.7661 38.2596 18.8475C37.8186 18.9139 37.3604 18.8603 36.8765 18.8603Z"
                fill="white"
            />
        </g>
        <g class="colored">
            <circle cx="22" cy="22" r="22" fill="white" />
            <path
                d="M28.6022 23.0791V20.8095H30.8183V18.9682H29.4608V16.6879H30.7241C30.7819 16.6151 30.814 16.5937 30.8183 16.568C30.8768 16.1569 31.1202 15.9506 31.5484 15.9492C32.0066 15.9492 32.4648 15.9535 32.9208 15.9599C32.9637 15.9599 33.0043 15.992 33.06 16.0135C33.0686 16.0691 33.0921 16.1291 33.0836 16.1847C33.0172 16.6087 33.2292 16.7115 33.6081 16.6815C34.7558 16.5958 35.7257 16.9641 36.2438 18.0239C36.4922 18.5314 36.5029 19.163 36.58 19.7454C36.625 20.0708 36.5886 20.4091 36.5886 20.7517C36.8926 20.7967 37.1495 20.816 37.3979 20.8738C38.3978 21.105 39.0808 21.9593 39.0872 22.987C39.0937 24.0255 39.0979 25.0639 39.0915 26.1023C39.0851 27.2243 38.2736 28.0208 37.1495 28.0336C36.5372 28.04 35.9163 28.0722 35.4067 27.6461C34.9485 27.2628 34.6402 26.8003 34.6915 26.1195C35.2889 26.1195 35.852 26.1023 36.4109 26.1259C36.7449 26.1409 36.8326 26.006 36.8241 25.6998C36.807 25.0596 36.8241 24.4173 36.8112 23.7771C36.8027 23.3146 36.595 23.0941 36.1411 23.0877C35.2418 23.0727 34.3404 23.0919 33.439 23.0791C33.1007 23.0748 33.0836 23.2611 33.0836 23.518C33.09 24.8305 33.0879 26.143 33.0879 27.4577C33.0879 27.6247 33.0879 27.7895 33.0879 28.0293C32.377 28.0293 31.6983 28.04 31.0217 28.0122C30.9575 28.0101 30.8568 27.7553 30.8568 27.6161C30.844 26.2886 30.829 24.959 30.8568 23.6315C30.8654 23.1862 30.7348 23.0448 30.2937 23.0727C29.7477 23.1091 29.1975 23.0812 28.6001 23.0812L28.6022 23.0791ZM34.2912 20.7603C34.2912 20.27 34.3083 19.8032 34.2805 19.3386C34.274 19.2187 34.1498 19.0517 34.0364 19.0024C33.4668 18.7476 33.0964 18.9982 33.0943 19.6126C33.0943 19.6726 33.0943 19.7347 33.0943 19.7946C33.0928 20.5355 33.4925 20.8573 34.2933 20.7603H34.2912Z"
                fill="#FF2E4D"
            />
            <path
                d="M27.758 16.6902V19.0197H26.347C26.332 19.1932 26.3085 19.3238 26.3085 19.4544C26.3063 21.4242 26.3149 23.394 26.2978 25.3617C26.2935 25.7471 26.4155 25.867 26.7923 25.8499C27.3533 25.8242 27.9143 25.8434 28.5074 25.8434V28.0038H20.7651C21.1099 27.3015 21.4182 26.6399 21.765 26.0019C21.8207 25.9012 22.0477 25.8541 22.1954 25.8499C22.7885 25.8327 23.3816 25.8434 24.0089 25.8434V19.039H22.5572C22.5572 18.3003 22.5487 17.6173 22.5679 16.9364C22.5701 16.8508 22.7242 16.7009 22.8078 16.6988C24.4393 16.6838 26.0708 16.6881 27.7558 16.6881L27.758 16.6902Z"
                fill="#FF2E4D"
            />
            <path
                d="M7.86969 26.1793C8.23154 26.1793 8.56555 26.16 8.89528 26.1836C9.24214 26.2093 9.34278 26.068 9.34063 25.7361C9.33207 23.7213 9.34063 21.7065 9.34063 19.6918C9.34063 18.6084 9.33849 17.525 9.33849 16.4394C9.33849 16.2917 9.35134 16.1418 9.3599 15.9727H11.6059C11.6145 16.1525 11.6295 16.3174 11.6295 16.4822C11.6295 19.6725 11.638 22.8627 11.6209 26.053C11.6209 26.4234 11.5374 26.8152 11.4025 27.1621C11.1777 27.7402 10.7238 28.0463 10.0836 28.0335C9.76457 28.0271 9.43484 28.0571 9.12652 27.995C8.34716 27.8408 7.86755 27.1535 7.86755 26.1793H7.86969Z"
                fill="#FF2E4D"
            />
            <path
                d="M22.1273 19.0592C21.5043 20.2882 20.924 21.4272 20.3481 22.5706C20.1104 23.0416 20.1789 23.1487 20.7185 23.1551C21.0075 23.1594 21.2966 23.1551 21.6841 23.1551C21.3415 23.7739 21.0461 24.3327 20.7206 24.8723C20.6714 24.9536 20.4787 24.975 20.3502 24.9772C19.6329 24.9858 18.9157 24.9858 18.1984 24.9772C17.6439 24.9686 17.4126 24.7374 17.5518 24.1978C17.6867 23.6733 17.9436 23.1808 18.1684 22.6819C18.3847 22.2023 18.6266 21.7334 18.905 21.1617C18.3547 21.1617 17.9201 21.166 17.4854 21.1617C16.8345 21.1553 16.5562 20.8384 16.7874 20.2282C17.0829 19.4489 17.4768 18.7038 17.8408 17.9501C18.1192 17.3741 18.4082 16.8024 18.7101 16.2372C18.7722 16.1194 18.9114 15.9696 19.0206 15.9653C19.6565 15.9396 20.2924 15.9524 21.0225 15.9524C20.6007 16.7661 20.2153 17.5026 19.8342 18.2413C19.7079 18.4854 19.5687 18.723 19.4724 18.9778C19.4424 19.057 19.5088 19.2091 19.5794 19.2776C19.618 19.314 19.7614 19.2562 19.8535 19.224C19.9862 19.1769 20.1125 19.072 20.2432 19.0699C20.8491 19.0528 21.455 19.0635 22.1316 19.0635L22.1273 19.0592Z"
                fill="#FF2E4D"
            />
            <path
                d="M5.50357 19.082H7.81597C7.77743 19.9535 7.7967 20.8078 7.68964 21.6471C7.52478 22.9424 7.3021 24.2335 7.04089 25.5118C6.94026 26.0064 6.72186 26.501 6.1716 26.8071C5.76907 26.0663 5.36654 25.3383 4.979 24.6018C4.93618 24.5204 4.94689 24.3941 4.97258 24.2999C5.40294 22.6812 5.54425 21.0326 5.47574 19.3625C5.47146 19.2747 5.49287 19.1869 5.50572 19.082H5.50357Z"
                fill="#FF2E4D"
            />
            <path
                d="M14.8174 26.8114C14.2949 26.5095 14.1194 26.0342 13.9631 25.5632C13.4256 23.9381 13.3079 22.2487 13.2329 20.5551C13.2115 20.0734 13.2287 19.5895 13.2287 19.082H15.479C15.4875 19.1998 15.5068 19.3154 15.5046 19.4289C15.4533 21.069 15.6053 22.6877 16.0121 24.2828C16.0378 24.3813 16.0635 24.5097 16.0207 24.589C15.6331 25.3255 15.2306 26.0556 14.8174 26.8136V26.8114Z"
                fill="#FF2E4D"
            />
            <path
                d="M16.0332 27.8732C16.44 27.0874 16.7954 26.398 17.1252 25.7578H20.6815C20.279 26.5179 19.9343 27.1924 19.5639 27.8497C19.5061 27.9524 19.3241 28.0359 19.1978 28.0381C18.3456 28.0531 17.4913 28.0509 16.6391 28.0338C16.455 28.0295 16.273 27.9396 16.0332 27.8732Z"
                fill="#FF2E4D"
            />
            <path
                d="M36.8766 18.8643C36.8766 18.4403 36.8466 18.0292 36.883 17.6267C36.9323 17.0936 37.414 16.6953 37.9493 16.6953C38.5103 16.6953 38.9834 17.1 39.0605 17.6438C39.1376 18.1941 38.795 18.77 38.2598 18.8514C37.8187 18.9178 37.3605 18.8643 36.8766 18.8643Z"
                fill="#FF2E4D"
            />
        </g>
    </svg>
</template>
