<template>
    <svg class="svg-social" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
        <g class="normal">
            <circle cx="22" cy="22" r="22" fill="black" fill-opacity="1" />
            <path
                d="M36.986 25.2207C36.986 20.9204 32.6827 17.418 27.8504 17.418C22.7342 17.418 18.7029 20.9234 18.7029 25.2207C18.7029 29.518 22.7313 33.0234 27.8504 33.0234C28.9232 33.0234 30.005 32.7515 31.0779 32.4855L34.0274 34.0993L33.2176 31.4127C35.3782 29.793 36.986 27.6443 36.986 25.2207ZM25.0204 24.0253C24.348 24.0253 23.8011 23.4815 23.8011 22.8061C23.8011 22.1307 24.345 21.5868 25.0204 21.5868C25.6958 21.5868 26.2396 22.1307 26.2396 22.8061C26.2396 23.4815 25.6958 24.0253 25.0204 24.0253ZM30.9374 24.0164C30.265 24.0164 29.7182 23.4725 29.7182 22.7971C29.7182 22.1217 30.262 21.5778 30.9374 21.5778C31.6128 21.5778 32.1567 22.1217 32.1567 22.7971C32.1567 23.4725 31.6128 24.0164 30.9374 24.0164Z"
                fill="white"
            />
            <path
                d="M17.3554 9.35156C11.4443 9.35156 6.6001 13.3799 6.6001 18.4991C6.6001 21.4546 8.21085 23.8782 10.9034 25.7609L9.82758 28.9944L13.587 27.1087C14.9318 27.3747 16.0106 27.6466 17.3554 27.6466C17.6931 27.6466 18.0278 27.6317 18.3595 27.6048C18.1503 26.8846 18.0278 26.1345 18.0278 25.3515C18.0278 20.6507 22.0621 16.8375 27.1723 16.8375C27.5219 16.8375 27.8656 16.8614 28.2093 16.9003C27.2769 12.5701 22.6419 9.35156 17.3554 9.35156ZM13.7424 16.7329C12.9355 16.7329 12.2811 16.0785 12.2811 15.2716C12.2811 14.4647 12.9355 13.8103 13.7424 13.8103C14.5493 13.8103 15.2037 14.4647 15.2037 15.2716C15.2037 16.0785 14.5493 16.7329 13.7424 16.7329ZM21.2523 16.7329C20.4454 16.7329 19.7909 16.0785 19.7909 15.2716C19.7909 14.4647 20.4454 13.8103 21.2523 13.8103C22.0591 13.8103 22.7136 14.4647 22.7136 15.2716C22.7136 16.0785 22.0591 16.7329 21.2523 16.7329Z"
                fill="white"
            />
        </g>
        <g class="colored">
            <circle cx="22" cy="22" r="22" fill="white" />
            <path
                d="M6.6001 19.163C6.6001 21.9535 8.07685 24.4636 10.3868 26.161C10.5725 26.2951 10.6942 26.5158 10.6942 26.7653C10.6942 26.8478 10.6777 26.9221 10.655 27.0005C10.4714 27.6976 10.1744 28.8155 10.162 28.867C10.1393 28.9557 10.1022 29.0465 10.1022 29.1372C10.1022 29.3414 10.2652 29.5064 10.4673 29.5064C10.5477 29.5064 10.6117 29.4775 10.6777 29.4383L13.0784 28.0338C13.2599 27.9286 13.4497 27.8626 13.66 27.8626C13.7714 27.8626 13.8807 27.8791 13.9838 27.9121C15.1038 28.238 16.3103 28.4195 17.5623 28.4195C23.6157 28.4195 28.5265 24.2738 28.5265 19.1609C28.5265 14.048 23.6178 9.90234 17.5643 9.90234C11.5109 9.90234 6.6001 14.048 6.6001 19.163Z"
                fill="white"
            />
            <path
                d="M6.6001 19.163C6.6001 21.9515 8.07685 24.4636 10.3889 26.161C10.5745 26.2951 10.6962 26.5158 10.6962 26.7653C10.6962 26.8478 10.6797 26.9221 10.657 27.0005C10.4735 27.6976 10.1765 28.8155 10.1641 28.867C10.1414 28.9557 10.1043 29.0465 10.1043 29.1372C10.1043 29.3414 10.2672 29.5064 10.4693 29.5064C10.5498 29.5064 10.6137 29.4775 10.6797 29.4383L13.0805 28.0338C13.262 27.9286 13.4517 27.8626 13.6621 27.8626C13.7735 27.8626 13.8828 27.8791 13.9859 27.9121C15.1058 28.238 16.3124 28.4195 17.5643 28.4195C23.6178 28.4195 28.5286 24.2738 28.5286 19.1609C28.5286 14.048 23.6178 9.90234 17.5643 9.90234C11.5109 9.90234 6.6001 14.048 6.6001 19.163Z"
                fill="url(#paint0_linear_2138_691)"
            />
            <path
                d="M18.6122 26.0378C18.6122 30.2968 22.7001 33.7494 27.7429 33.7494C28.7844 33.7494 29.7909 33.5989 30.7232 33.3266C30.8077 33.2998 30.8985 33.2854 30.9913 33.2854C31.1666 33.2854 31.3254 33.339 31.476 33.4277L33.4746 34.5971C33.5302 34.6301 33.5839 34.6549 33.6499 34.6549C33.819 34.6549 33.9531 34.5167 33.9531 34.3455C33.9531 34.2692 33.9242 34.1929 33.9036 34.1207C33.8912 34.0774 33.6457 33.1472 33.4931 32.5656C33.4746 32.4996 33.4622 32.4377 33.4622 32.3696C33.4622 32.1613 33.5632 31.9798 33.7179 31.8664C35.6422 30.4536 36.8736 28.3622 36.8736 26.0378C36.8736 21.7787 32.7857 18.3281 27.7429 18.3281C22.7001 18.3281 18.6122 21.7808 18.6122 26.0378Z"
                fill="url(#paint1_linear_2138_691)"
            />
            <path
                d="M29.6633 23.6849C29.6633 24.3696 30.2099 24.9244 30.8843 24.9244C31.5588 24.9244 32.1074 24.3696 32.1074 23.6849C32.1074 23.0001 31.5608 22.4453 30.8843 22.4453C30.2078 22.4453 29.6633 23.0001 29.6633 23.6849Z"
                fill="#919191"
            />
            <path
                d="M23.552 23.6849C23.552 24.3696 24.0986 24.9244 24.7751 24.9244C25.4516 24.9244 25.9981 24.3696 25.9981 23.6849C25.9981 23.0001 25.4516 22.4453 24.7751 22.4453C24.0986 22.4453 23.552 23.0001 23.552 23.6849Z"
                fill="#919191"
            />
            <path
                d="M15.3633 16.1863C15.3633 17.0072 14.7074 17.6733 13.8969 17.6733C13.0863 17.6733 12.4304 17.0072 12.4304 16.1863C12.4304 15.3654 13.0863 14.6992 13.8969 14.6992C14.7074 14.6992 15.3633 15.3654 15.3633 16.1863Z"
                fill="#168743"
            />
            <path
                d="M22.6957 16.1863C22.6957 17.0072 22.0398 17.6733 21.2293 17.6733C20.4187 17.6733 19.7628 17.0072 19.7628 16.1863C19.7628 15.3654 20.4187 14.6992 21.2293 14.6992C22.0398 14.6992 22.6957 15.3654 22.6957 16.1863Z"
                fill="#168743"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_2138_691"
                x1="17.5643"
                y1="29.5085"
                x2="17.5643"
                y2="9.90234"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.06" stop-color="#05CD66" />
                <stop offset="0.22" stop-color="#0DD068" />
                <stop offset="0.48" stop-color="#26DA6F" />
                <stop offset="0.81" stop-color="#4DEA7A" />
                <stop offset="0.95" stop-color="#61F380" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2138_691"
                x1="27.7429"
                y1="34.6549"
                x2="27.7429"
                y2="18.3281"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.08" stop-color="#D9D9D9" />
                <stop offset="1" stop-color="#F0F0F0" />
            </linearGradient>
        </defs>
    </svg>
</template>
