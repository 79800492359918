<template>
    <svg class="svg-social" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g class="normal">
            <circle cx="16" cy="16" r="16" fill="black" fill-opacity="1" />
            <path
                d="M13.2609 8.64447H8.53565L14.1675 16.0647L8.84308 22.2159H11.1327L15.2355 17.4806L18.8467 22.2159H23.4643L17.5943 14.3856L22.5499 8.64447H20.2679L16.5109 12.9619L13.2609 8.64447ZM12.6256 10.0016L20.7499 20.8588L19.4659 20.8203L11.2499 10.0016H12.6256Z"
                fill="white"
            />
        </g>
        <g class="colored">
            <circle cx="16" cy="16" r="16" fill="#000000" />
            <path
                d="M13.2609 8.64447H8.53565L14.1675 16.0647L8.84308 22.2159H11.1327L15.2355 17.4806L18.8467 22.2159H23.4643L17.5943 14.3856L22.5499 8.64447H20.2679L16.5109 12.9619L13.2609 8.64447ZM12.6256 10.0016L20.7499 20.8588L19.4659 20.8203L11.2499 10.0016H12.6256Z"
                fill="white"
            />
        </g>
    </svg>
</template>

<style lang="less" scoped>
    .svg-social {
        .colored {
            opacity: 0;
            transition: all 0.5s ease;
            transition-delay: 0.2s;
        }
        &:hover {
            // .normal {
            //     opacity: 0;
            // }
            .colored {
                opacity: 1;
            }
        }
    }
</style>
